import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import VirtualList from "rc-virtual-list";
import {
  clearState,
  CompleteAllTasksApi,
} from "../../Equipment/EquipmentList/EquipmentsAPI";
import { clearActionsState } from "../Actions/ActionsAPI";
import { clearWorkDoneState } from "../../WorkDone/WorkDoneAPI";
import { clearStageStateApi } from "../../Stage/StageAPI";
import { Document, Page, pdfjs } from "react-pdf";
import {
  Card,
  Dropdown,
  Tag,
  Row,
  Col,
  Typography,
  Button,
  Modal,
  List,
  Avatar,
  message,
  Spin,
  Pagination,
} from "antd";
import { getActualHours2 } from "../../Common/Common";
import {
  updateAppointmentStatus,
  getPreviewReportAPI,
  emailAppointment,
  getPreviewReportWithTasksAPI,
} from "../Actions/ActionsAPI";
import { useHistory } from "react-router-dom";
import EmptyContainer from "features/CommonComponents/Empty/Empty";
import SketchCanvas from "../../CommonComponents/Drawing/SketchCanvas";
import CaptureSignature from "../CaptureSignature/CaptureSignature";
import { DoubleRightOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import {
  setSelectedAppointment,
  markAllServiceTemplatesAsCompleted,
} from "./AppointmentsAPI";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import {
  showMoreEllipsis,
  showMoreDispatchEllipsis,
} from "features/Summary/SummarySlice";

const { Title, Text, Paragraph } = Typography;

const RenderVirtualList = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { previewReportData } = useSelector((state) => state.ActionsSlice);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { appointmentList } = useSelector((state) => state.AppointmentsSlice);
  const [ellipsis, setEllipsis] = useState(true);
  const { loading } = useSelector((state) => state.AppointmentsSlice);
  const { selectedId } = useSelector((state) => state.AppointmentsSlice);
  const { redStartDate, redEndDate } = useSelector(
    (state) => state.AppointmentsSlice
  );
  const [pdfUrl, setPdfUrl] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [reportModal, setReportModal] = useState(false);
  const [reportInput, setReportInput] = useState();
  const [emailModal, setEmailModal] = useState(false);
  const [emailInput, setEmailInput] = useState();
  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
  const { equipmentList } = useSelector((state) => state.EquipmentsSlice);
  const { equipmentGUID } = useSelector((state) => state.EquipmentDetailsSlice);
  const [equipmentId, setEquipmentId] = useState();
  const serviceOrdersOfPM = ["PM", "PMC", "PMCB", "PMCT"];
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  useEffect(() => {
    if (equipmentGUID && equipmentList?.length) {
      const equipment = equipmentList?.find((x) => x.id === equipmentGUID);
      if (equipment) {
        setEquipmentId(equipment?.equipmentNbr);
      }
    }
  }, [equipmentGUID, equipmentList]);
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getColorByStatus = (key) => {
    switch (key) {
      case "Not Started":
        return "blue";
      case "In Process":
        return "warning";
      case "Completed":
        return "success";
      case "Closed":
        return "success";
      case "On Hold":
        return "error";
      case "Canceled":
        return "error";
      case "Paused":
        return "default";
      case "open":
        return "warning";
      default:
        return "default";
        break;
    }
  };

  const [isCaptureSignatureModalOpen, setCaptureSignatureModalOpen] =
    useState(false);
  const [captureSignatureData, setCaptureSignatureData] = useState();

  const showCaptureSignatureModal = (obj) => {
    setCaptureSignatureModalOpen((prevData) => ({ ...prevData, ...true }));
    setCaptureSignatureData((prevData) => ({ ...prevData, ...obj }));
  };

  const handleReportTasks = () => {
    dispatch(clearActionsState());
    var obj = {
      appointmentNbr: reportInput?.appointmentNbr,
      serviceOrderType: reportInput?.serviceOrderType?.trim(),
    };
    dispatch(getPreviewReportWithTasksAPI(obj)).then((res) => {
      if (res) {
        // setIsModalOpen(true);
        fetch(`data:application/pdf;base64,${res}`)
          .then((res) => res.blob())
          .then((val) => {
            const url = URL.createObjectURL(val);
            window.open(url, "_blank");
          });
      }
    });
    setReportModal(false);
  };
  const handleReport = () => {
    dispatch(clearActionsState());
    var obj = {
      appointmentNbr: reportInput?.appointmentNbr,
      serviceOrderType: reportInput?.serviceOrderType?.trim(),
    };
    dispatch(getPreviewReportAPI(obj)).then((res) => {
      if (res) {
        const base64Data = `data:application/pdf;base64,${res}`;
        fetch(base64Data)
          .then((res) => res.blob())
          .then((blob) => {
            const url = URL.createObjectURL(blob);
            window.open(url, "_blank");
          });
      }
    });
    setReportModal(false);
  };

  const handlesendEmail = (id) => {
    var obj = {
      appointmentNbr: emailInput?.appointmentNbr,
      serviceOrderType: emailInput?.serviceOrderType?.trim(),
      appStatus: emailInput?.status,
      noteId: emailInput?.noteID,
      language: null,
      userName: emailInput?.staffMemberEmployeeID?.trim(),
      appointmentNoteId: emailInput?.noteID,
      reportType: id,
    };
    dispatch(emailAppointment(obj));
    setEmailModal(false);
  };

  const getItemsByState = (item) => {
    var stateList = [];
    switch (item?.status) {
      case "Not Started":
        stateList = [
          {
            key: "1",
            label: (
              <a rel="noopener noreferrer">
                {" "}
                <FormattedMessage id="AppointmentStatus.Start" />
              </a>
            ),
            action: "Start",
          },
          {
            key: "2",
            label: (
              <a rel="noopener noreferrer">
                <FormattedMessage id="AppointmentStatus.Cancel" />
              </a>
            ),
            action: "Cancel",
          },
        ];
        break;
      case "Canceled":
        stateList = [
          {
            key: "1",
            label: (
              <a rel="noopener noreferrer">
                <FormattedMessage id="AppointmentStatus.Reopen" />
              </a>
            ),
            action: "Reopen",
          },
        ];
        break;
      case "In Process":
        stateList = [
          {
            key: "1",
            label: (
              <a rel="noopener noreferrer">
                <FormattedMessage id="AppointmentStatus.Pause" />
              </a>
            ),
            action: "Pause",
          },
          {
            key: "2",
            label: (
              <a rel="noopener noreferrer">
                {" "}
                <FormattedMessage id="AppointmentStatus.Complete" />
              </a>
            ),
            action: "Complete",
          },
          serviceOrdersOfPM?.includes(item?.serviceOrderType?.trim()) && {
            key: "3",
            label: (
              <a rel="noopener noreferrer">
                {" "}
                <FormattedMessage id="AppointmentStatus.MarkAllTasksComplete" />
              </a>
            ),
            action: "MarkAllTasksComplete",
          },
        ];
        break;
      case "Paused":
        stateList = [
          {
            key: "1",
            label: (
              <a rel="noopener noreferrer">
                {" "}
                <FormattedMessage id="AppointmentStatus.Resume" />
              </a>
            ),
            action: "Resume",
          },
          {
            key: "2",
            label: (
              <a rel="noopener noreferrer">
                {" "}
                <FormattedMessage id="AppointmentStatus.Complete" />
              </a>
            ),
            action: "Complete",
          },
          serviceOrdersOfPM?.includes(item?.serviceOrderType?.trim()) && {
            key: "3",
            label: (
              <a rel="noopener noreferrer">
                <FormattedMessage id="AppointmentStatus.MarkAllTasksComplete" />
              </a>
            ),
            action: "MarkAllTasksComplete",
          },
        ];
        break;
      default:
        break;
    }
    if (!item?.finished) {
      stateList.push({
        key: "4",
        label: (
          <a rel="noopener noreferrer">
            {" "}
            <FormattedMessage id="AppointmentStatus.Finished" />
          </a>
        ),
        action: "Finished",
      });
    }
    // stateList.push({
    //   key: "5",
    //   label: (
    //     <a rel="noopener noreferrer">
    //       {" "}
    //       <FormattedMessage id="AppointmentStatus.PreviewReport" />
    //     </a>
    //   ),
    //   action: "PreviewReport",
    // });
    stateList.push({
      key: "7",
      label: (
        <a rel="noopener noreferrer">
          {" "}
          <FormattedMessage id="AppointmentStatus.PreviewReport" />
        </a>
      ),
      action: "PreviewReport",
    });
    stateList.push({
      key: "6",
      label: (
        <a rel="noopener noreferrer">
          <FormattedMessage id="AppointmentStatus.CaptureSignature" />
        </a>
      ),
      action: "CaptureSignature",
    });
    if (
      !(item?.status == "On Hold" || item?.status == "Awaiting") &&
      item?.signedReportID != null
    ) {
      stateList.push({
        key: "8",
        label: (
          <a rel="noopener noreferrer">
            <FormattedMessage id="Send Email" />
          </a>
        ),
        action: "SendEmail",
      });
    }
    return stateList;
  };
  let { userId } = useParams();
  useEffect(() => {
    const { pathname } = location;
    const parts = pathname.split("/");
    const appoointId = parts[2];
    if (appoointId) {
      dispatch(setSelectedAppointment(appoointId));
    }
  }, []);

  const handleClick = (e, item) => {
    switch (e.key) {
      case "3":
        // dispatch(
        //   CompleteAllTasksApi(
        //     item?.appointmentNbr,
        //     item?.serviceOrderType?.trim(),
        //     item?.customer
        //   )
        // );
        const input = {
          appointmentNbr: item?.appointmentNbr,
          serviceOrderType: item?.serviceOrderType?.trim(),
          customer: item?.customer,
          equipmentId: equipmentId,
        };
        dispatch(markAllServiceTemplatesAsCompleted(input));
        break;
      case "5":
        dispatch(clearActionsState());
        var obj = {
          appointmentNbr: item?.appointmentNbr,
          serviceOrderType: item?.serviceOrderType?.trim(),
        };
        dispatch(getPreviewReportAPI(obj)).then((res) => {
          if (res) {
            setIsModalOpen(true);

            fetch(`data:application/pdf;base64,${res}`)
              .then((res) => res.blob())
              .then((val) => {
                const url = URL.createObjectURL(val);
                setPdfUrl(url);
              });
          }
        });
        break;
      case "7":
        setReportModal(true);
        setReportInput(item);
        // dispatch(clearActionsState());
        // var obj = {
        //   appointmentNbr: item?.appointmentNbr,
        //   serviceOrderType: item?.serviceOrderType?.trim(),
        // };
        // dispatch(getPreviewReportAPI(obj)).then((res) => {
        //   if (res) {
        //     // setIsModalOpen(true);
        //     fetch(`data:application/pdf;base64,${res}`)
        //       .then((res) => res.blob())
        //       .then((val) => {
        //         const url = URL.createObjectURL(val);
        //         window.open(url, "_blank");
        //       });
        //   }
        // });
        break;
      case "6":
        var obj = {
          appointmentNbr: item?.appointmentNbr,
          appointmentNoteId: item?.noteID,
          serviceOrderType: item?.serviceOrderType?.trim(),
          userName: item?.staffMemberEmployeeID.trim(),
        };
        showCaptureSignatureModal(obj);
        break;
      case "8":
        dispatch(clearActionsState());
        setEmailModal(true);
        setEmailInput(item);
        break;
      case "9":
        var obj = {
          action: "Canceled",
          appointmentNbr: item?.appointmentNbr,
          appointmentNoteId: item?.noteID,
          serviceOrderType: item?.serviceOrderType?.trim(),
        };
        dispatch(updateAppointmentStatus(obj));
      case "10":
        var obj = {
          action: "Reopen",
          appointmentNbr: item?.appointmentNbr,
          appointmentNoteId: item?.noteID,
          serviceOrderType: item?.serviceOrderType?.trim(),
        };
        dispatch(updateAppointmentStatus(obj));
        break;
      default:
        let items = getItemsByState(item);
        let actionInfo = items.find((element) => element.key === e.key);
        let action = actionInfo.action;
        var obj = {
          action: action,
          appointmentNbr: item?.appointmentNbr,
          appointmentNoteId: item?.noteID,
          serviceOrderType: item?.serviceOrderType?.trim(),
        };
        dispatch(updateAppointmentStatus(obj));
    }
  };

  const onScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    if (
      e.currentTarget.scrollHeight - Math.floor(e.currentTarget.scrollTop) ===
      clientHeight
    ) {
      props.fetchList();
    }
    // if (
    //   e.currentTarget.scrollHeight - e.currentTarget.scrollTop - height <=
    //   1
    // ) {
    //   props.fetchList();
    // }
  };

  useEffect(() => {
    if (redStartDate != null && redEndDate != null) {
      clear();
    }
  }, [redStartDate, redEndDate]);
  const clear = () => {
    dispatch(clearState());
    dispatch(clearWorkDoneState());
    dispatch(clearStageStateApi());
  };

  const selectAppointment = (item) => {
    const { pathname } = location;
    const parts = pathname.split("/");
    const appoointId = parts[2];
    if (item?.noteID != appoointId) {
      clear();
    }
    dispatch(showMoreEllipsis());
    dispatch(showMoreDispatchEllipsis());
    const appointmentDetails = {
      appointmentNoteId: item?.noteID,
      appointmentNbr: item?.appointmentNbr,
    };
    JSON.stringify(appointmentDetails);
    localStorage.setItem(
      "selectedAppointment",
      JSON.stringify(appointmentDetails)
    );
    dispatch(setSelectedAppointment(item?.noteID));
    history.push(`/appointment/${item?.noteID}/summary`);
  };

  const showVisitHistory = (item) => {
    dispatch(setSelectedAppointment(item?.noteID));
    history.push(`/appointment/${item?.noteID}/visithistory`);
  };

  const updatePage = () => {
    setPageNumber(2);
  };
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };
  const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  };
  const { height, width } = useWindowDimensions();
  var selectedLanguage = localStorage.getItem("selectedlanguage");
  localStorage.setItem(
    "selectedlanguage",
    selectedLanguage ? selectedLanguage : "fr-FR"
  );
  console.log("selectedLanguage", selectedLanguage);
  const statusText = (status, language) => {
    const translations = {
      en: {
        canceled: "Canceled",
        paused: "Paused",
        not_started: "Not Started",
        in_process: "In Process",
        completed: "Completed",
        closed: "Closed",
        on_hold: "On Hold",
        open: "open",
      },
      fr: {
        canceled: "Annulé",
        paused: "En pause",
        not_started: "Pas commencé",
        in_process: "En cours",
        completed: "Terminé",
        closed: "Fermé",
        on_hold: "En attente",
        open: "ouvrir",
      },
    };
    const normalizedStatus = status.toLowerCase().replace(/\s+/g, "_");
    return translations[language]?.[normalizedStatus] || status;
  };
  const currentLanguage = selectedLanguage.startsWith("fr") ? "fr" : "en";
  return (
    <>
      <List>
        <Spin spinning={loading}>
          {appointmentList.length == 0 ? (
            <>
              {" "}
              <EmptyContainer
                description={
                  props.isOnSearchMode ? (
                    <FormattedMessage id="AppointmentStatus.Nomatchfound" />
                  ) : (
                    <FormattedMessage id="AppointmentStatus.NoAppointments" />
                  )
                }
              />{" "}
            </>
          ) : (
            <VirtualList
              data={appointmentList}
              height={Math.floor(height / 100) * 100 - 44}
              itemHeight={150}
              style={{ overflow: "auto" }}
              itemKey="email"
              onScroll={onScroll}
            >
              {(item) => (
                <div className="projects-new">
                  <Card
                    size="small"
                    bordered={false}
                    className={selectedId === item?.noteID && "card-active"}
                  >
                    <div className="pro-table">
                      <Row wrap={false}>
                        <Col
                          flex="300px"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => selectAppointment(item)}
                        >
                          <Row>
                            <Col flex="150px">
                              <Tag color={getColorByStatus(item?.status)}>
                                {/* {moment
                                (item?.scheduledStartDate)
                                .format("M-D-YYYY")} */}
                                {moment
                                  .utc(item?.scheduledStartDate)
                                  .local()
                                  .format("M-D-YYYY")}{" "}
                                |{" "}
                                {/* {moment(item?.scheduledStartDate).format(
                                "hh:mm A"
                              )} */}
                                {moment
                                  .utc(item?.scheduledStartDate)
                                  .local()
                                  .format("hh:mm A")}
                              </Tag>
                            </Col>
                            {/* <Col flex="20px">
                                <Paragraph></Paragraph>
                            </Col> */}
                            <Col flex="auto" className="text-center">
                              <Paragraph ellipsis={ellipsis}>
                                {item?.appointmentNbr}
                              </Paragraph>
                            </Col>
                          </Row>
                        </Col>

                        <Col flex="25px" className="text-left">
                          <Dropdown
                            trigger={["click"]}
                            menu={{
                              items: getItemsByState(item),
                              onClick: (e) => handleClick(e, item),
                            }}
                            placement="bottomRight"
                            arrow={{
                              pointAtCenter: true,
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {getItemsByState(item).length > 0 ? (
                              <a onClick={(e) => e.preventDefault(e)}>
                                <i className="simple-icon-options-vertical"></i>
                              </a>
                            ) : (
                              <></>
                            )}
                          </Dropdown>
                        </Col>
                      </Row>
                    </div>

                    <div className="appoin-details">
                      <Row align="middle">
                        <Col style={{ width: "calc(100% - 30px)" }}>
                          <div
                            onClick={() => selectAppointment(item)}
                            style={{ cursor: "pointer" }}
                          >
                            <Row>
                              <Col span={12}>
                                <Title level={5} className="text-primary">
                                  {item?.customerID?.trim()}
                                </Title>
                              </Col>
                              <Col span={12} className="text-right">
                                <Tag color={getColorByStatus(item?.status)}>
                                  {statusText(item?.status, currentLanguage)}
                                </Tag>
                              </Col>
                            </Row>

                            {item?.addressLine1 && (
                              <Text>{item?.addressLine1?.trim()}</Text>
                            )}
                          </div>
                        </Col>
                        <Col style={{ width: "30px" }}>
                          {item?.location && item?.customerID && (
                            <div className="appoin-right-icon">
                              <a onClick={() => showVisitHistory(item)}>
                                <i className="simple-icon-arrow-right"></i>
                              </a>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </div>
                    <div
                      className="app-bottom"
                      onClick={() => selectAppointment(item)}
                    >
                      <Row>
                        <Col span={12}>
                          <Paragraph ellipsis={ellipsis} className="text-small">
                            <i className="iconsminds-clock"></i>
                            {item?.actualDuration
                              ? getActualHours2(item?.actualDuration)
                              : ""}
                          </Paragraph>
                        </Col>
                        <Col span={12}>
                          <Paragraph ellipsis={ellipsis} className="text-small">
                            <i className="iconsminds-information"></i>{" "}
                            {item?.serviceOrderType}
                          </Paragraph>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </div>
              )}
            </VirtualList>
          )}
        </Spin>
      </List>
      <Modal
        title={<FormattedMessage id="AppointmentStatus.PreviewReport" />}
        className="preview-modal"
        open={isModalOpen}
        onOk={handleOk}
        width={900}
        onCancel={handleCancel}
        footer={[
          <Button type="default" onClick={handleCancel}>
            <FormattedMessage id="Common.Cancel" />
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            <FormattedMessage id="Appointment.SendEmail" />
          </Button>,
        ]}
      >
        <div className="equipment" style={{ paddingTop: "0" }}>
          <Row gutter={16} className="mt-2 mb-2">
            <Col span={24} className="scroll-wrapper">
              {/* <iframe
                src={`data:application/pdf;base64,${previewReportData}`}
                title="title"
                style={{ width: "100%", height: "400" }} allowFullScreen webkitallowfullscreen="true"
              ></iframe> */}

              {/* <iframe
                src={pdfUrl}
                title="title"
                style={{ width: "100%", height: "400px" }} allowFullScreen webkitallowfullscreen="true"
              ></iframe> */}

              {/* <iframe
                src={`https://docs.google.com/gview?url=${pdfUrl}&embedded=true`}
                title="title"
                style={{ width: "100%", height: "400px" }}
              ></iframe> */}

              <Document
                file={`data:application/pdf;base64,${previewReportData}`}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    size="A4"
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                  />
                ))}
              </Document>
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        title={<FormattedMessage id="AppointmentReport.Selectreporttype" />}
        open={reportModal}
        onOk={() => setReportModal(false)}
        onCancel={() => setReportModal(false)}
        footer={[
          <Button key="submit" type="primary" onClick={handleReportTasks}>
            <FormattedMessage id="AppointmentReport.Reportwithtask" />
          </Button>,
          <Button key="submit" type="primary" onClick={handleReport}>
            <FormattedMessage id="AppointmentReport.Reportwithouttask" />
          </Button>,
        ]}
      >
        <p>
          <FormattedMessage id="AppointmentReport.Pleaseselectthereporttypefrombelowoptionstopreview" />
        </p>
      </Modal>
      <Modal
        title={<FormattedMessage id="AppointmentReport.Selectreporttype" />}
        open={emailModal}
        onOk={() => setEmailModal(false)}
        onCancel={() => setEmailModal(false)}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => handlesendEmail(0)}
          >
            <FormattedMessage id="AppointmentReport.Reportwithtask" />
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => handlesendEmail(1)}
          >
            <FormattedMessage id="AppointmentReport.Reportwithouttask" />
          </Button>,
        ]}
      >
        <p>
          <FormattedMessage id="AppointmentReport.PleaseselectthereporttypefrombelowoptionstoSendEmail" />
        </p>
      </Modal>
      <CaptureSignature
        signatureCapture={isCaptureSignatureModalOpen}
        setSignatureCapture={setCaptureSignatureModalOpen}
        Appdetails={captureSignatureData}
      />
    </>
  );
};

export default RenderVirtualList;
